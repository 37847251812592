import $ from 'jquery';
window.$ = $;
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/reset.css";
import { Button, Form, FormItem, Input, Checkbox, Segmented, Avatar, Textarea, Modal, Upload, Radio, Popconfirm } from "ant-design-vue";

// highlight 的样式，依赖包，组件
import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

createApp(App)
    .use(store)
    .use(router)
    .use(hljsVuePlugin)
    .use(Button)
    .use(Form)
    .use(FormItem)
    .use(Input)
    .use(Checkbox)
    .use(Segmented)
    .use(Avatar)
    .use(Textarea)
    .use(Modal)
    .use(Upload)
    .use(Radio)
    .use(Popconfirm)
    .mount("#app");
