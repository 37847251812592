import { createStore } from "vuex";

export default createStore({
  state: {
    loading: false,
  },
  getters: {},
  mutations: {
    setLoadingState(state, e) {
      state.loading = e;
    },
  },
  actions: {},
  modules: {},
});
